import { useReducer } from 'react'
import { getPossibleUserMapSubjects } from './calculation'

export type SelectionState = {
  user: number | undefined
  item: number | undefined
  impression: number | undefined
  question: number | undefined
  preference: number | undefined
  userAttribute: number[]
  showUsersAsDots: boolean

  userMapSubject: UserMapSubject | undefined
  inverseItemsOrder: boolean
}

export type UserMapSubject =
  | 'distribution'
  | 'preference-or-impression'
  | 'question'

export type SelectionStateAction =
  | { type: 'reset' }
  | { type: 'update'; value: Partial<SelectionState> }

function getInitialState(): SelectionState {
  let override: SelectionState | undefined

  // accept initial state from url hash
  block: try {
    const hash = location.hash.slice(1)
    if (hash === '') break block

    override = JSON.parse(decodeURIComponent(hash))
  } catch (e) {
    console.error(e)
  }

  return {
    user: undefined,
    item: undefined,
    impression: undefined,
    question: undefined,
    preference: undefined,
    userAttribute: [],
    showUsersAsDots: true,
    userMapSubject: undefined,
    inverseItemsOrder: false,
    ...override,
  }
}

function reduce(
  state: SelectionState,
  action: SelectionStateAction,
): SelectionState {
  switch (action.type) {
    case 'reset':
      return getInitialState()

    case 'update': {
      const update = action.value
      const newState: SelectionState = { ...state, ...update }
      if (update.impression !== undefined) {
        newState.preference = undefined
      }
      if (update.preference !== undefined) {
        newState.impression = undefined
      }

      newState.userMapSubject = getNextMapSubject(
        state,
        newState,
        update,
        (s) => s.userMapSubject,
        getPossibleUserMapSubjects,
        getInitialState,
      )

      return newState
    }
  }
}

export function useSelectionState(): [
  state: SelectionState,
  action: (a: SelectionStateAction) => void,
] {
  const [state, action] = useReducer(reduce, undefined, getInitialState)

  return [state, action]
}

function getNextMapSubject<State, Subject>(
  prevState: State,
  newState: State,
  update: Partial<State>,
  getSubjectFromState: (state: State) => Subject | undefined,
  getPossibleSubjects: (state: State) => Subject[],
  getInitialState: () => State,
): Subject | undefined {
  // 状態の変更前と変更後のpossibleSubjectsを比較して、
  // - 異なる場合
  //    - 現在のsubjectが新しいpossibleSubjectsに含まれていない場合、possibleSubjectsの最初のsubjectに変更
  //    - 含まれている場合
  //      - 変更後のpossibleSubjectsに増えたsubjectがある場合は、それに変更
  //      - 増えていない場合は、何もしない
  // - 同じ場合
  //   - 初期状態+updateでできるpossibleSubjectsの最初のsubjectに変更
  //     (possibleSubjectsが空の場合は、何もしない)

  const prevPossibleSubjects = new Set(getPossibleSubjects(prevState))
  const newPossibleSubjects = new Set(getPossibleSubjects(newState))

  const newSubject = getSubjectFromState(newState)

  if (equalSet(prevPossibleSubjects, newPossibleSubjects)) {
    const possibleSubjects = getPossibleSubjects({
      ...getInitialState(),
      ...update,
    })
    return possibleSubjects[0] ?? newSubject
  } else {
    if (!hasElement(newPossibleSubjects, newSubject)) {
      return firstElement(newPossibleSubjects)
    }

    const addedSubjects = subtractSet(newPossibleSubjects, prevPossibleSubjects)
    return firstElement(addedSubjects) ?? newSubject
  }
}

function equalSet<T>(a: Set<T>, b: Set<T>): boolean {
  if (a.size !== b.size) return false
  for (const item of a) {
    if (!b.has(item)) return false
  }
  return true
}

function hasElement<T>(set: Set<T>, element: T): boolean {
  return set.has(element)
}

function firstElement<T>(set: Set<T>): T | undefined {
  const [first] = set
  return first
}

function subtractSet<T>(a: Set<T>, b: Set<T>): Set<T> {
  const result = new Set(a)
  for (const item of b) {
    result.delete(item)
  }
  return result
}
